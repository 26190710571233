





































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SessionClosingInfoProperties } from "./SessionClosingInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SessionClosingNotifyProperties } from "./SessionClosingNotifyView.vue";
import SessionClosingService from "@/services/SessionClosingService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false
        },
        filter: {
            customer: ""
        },
        saving: false,
        customers: []
    })
})
export default class SessionClosingCustomerView extends Vue {
    @Prop() private properties: SessionClosingInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
	@Prop() private sessionClosingNotify: SessionClosingNotifyProperties;
    private sessionClosingService = new SessionClosingService();
    private queueHandle: number = 0;

    public get headers() {
        return [
            {
                text: this.$t("text.customer"),
                value: "customer"
            },
            {
                text: this.$t("text.total-quantity"),
                value: "totalQuantity"
            },
            {
                text: this.$t("text.total-weight-g"),
                value: "totalWeight"
            },
            {
                text: this.$t("text.total-amount"),
                value: "totalAmount"
            },
            {
                text: this.$t("text.notified"),
                value: "notified",
                align: "center"
            },
            {
                value: "action",
                align: "center",
                width: "50px"
            }
        ];
    }

    public get anyNotifiable() {
        const customers: any[] = this.$data.customers;
		for (var i = 0; i < customers.length; i++) {
			if (this.isNotifiable(customers[i])) {
				return true;
			}
		}
		return false;
    }

    public isNotifiable(item: any) {
        return item.notifiable > 0 && item.notifiable !== item.notified;
    }

	public navigatorUrl(item: any) {
		const facebookAccountId = item.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

    public created() {
        this.properties.events.subscribe("loaded", this.load);
        this.properties.events.subscribe("load-customer", this.load);
        this.load();
    }

    public destroyed() {
        this.properties.events.remove("loaded", this.load);
        this.properties.events.remove("load-customer", this.load);
    }

    public loadQueue(delay: number = 500) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
            this.queueHandle = 0;
            this.load();
        }, delay);
    }

    public async load() {
        const sessionClosing = this.properties.sessionClosing;
        if (sessionClosing && sessionClosing.id) {
            const pageNo = this.$data.table.pageNo;
            const rowCount = this.$data.table.recordCount;
            const rowStart = (pageNo - 1) * rowCount;

            this.$data.table.loading = true;
            const filter = this.$data.filter;
            try {
                const r = await this.sessionClosingService.customer({
                    rowStart: rowStart,
                    rowCount: rowCount,
                    countRecord: true,
                    sessionClosingId: sessionClosing.id,
                    searchCustomer: filter.customer
                });

                const customers = r.data.sessionClosingCustomers;

				const labelPsid = this.$t('text.psid');
				for (var i = 0; i < customers.length; i++) {
					const d = customers[i];
					const n = d.fullName;
					const p = d.pageScopedId ?? "";
					const t = p !== "" ? `${labelPsid}: ${p}` : "";

					d.customer = `${n}\n${t}`.trim();
					d.totalQuantity ??= 0;
					d.totalWeight ??= 0;
					d.totalWeight ??= 0;
				}

                this.$data.customers = customers.sort((lhs, rhs) => {
					return rhs.facebookAccountId - lhs.facebookAccountId;
				});
				
                var count = Math.ceil(r.data.count / rowCount);
                if (count < 1) count = 1;
                await Vue.nextTick();
                this.$data.table.pageCount = count;
                if (pageNo > count) {
                    this.$data.table.pageNo = count;
                }
            } catch (e) {
                if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                    var m = ExceptionUtil.getMessage(e);
                    AlertDialogProperties.error(this.alertDialog, null, m);
                    this.alertDialog.visible = true;
                }
            } finally {
                this.$data.table.loading = false;
            }
        }
    }

    public sendAll() {
        this.sessionClosingNotify.facebookAccountId = 0;
        this.sessionClosingNotify.sendAll = true;
        this.sessionClosingNotify.customers = this.$data.customers;
        this.sessionClosingNotify.visible = true;
    }

    public send(record: any) {
        this.sessionClosingNotify.facebookAccountId = record.facebookAccountId;
        this.sessionClosingNotify.sendAll = false;
        this.sessionClosingNotify.customers = [];
        this.sessionClosingNotify.visible = true;
    }
}
